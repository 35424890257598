import { fetchServer } from "@features/utils/fetch-server";
import {
  CommandType,
  PrepOrderRequest,
  ReassortProductLine,
  ReturnType,
  SupplierOrderPrepHeader,
  SupplierOrderPrepLine,
  SupplierOrderRequestFilter,
} from "../types";
import { CommonPagination, CommonPaginationRequest } from "@features/types";

export class SupplierApiClient {
  static getMotifRefus = async () => {
    const data = await fetchServer("/supplier/return/reason");
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result.map((el: { code: any; lib: any }) => {
      return { label: el.lib, value: el.code };
    });
  };

  static getModesExpedition = async () => {
    const data = await fetchServer("/supplier/shippingmode");
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result.map((el: { code: any; lib: any }) => {
      return { label: el.lib, value: el.code };
    });
  };

  static getFournBl = async (
    codeFourn?: string,
    numBL?: string,
    recepOuver?: boolean,
    recepTerm?: boolean
  ) => {
    const queryParams: { [key: string]: any } = {};

    if (codeFourn !== undefined && codeFourn !== "")
      queryParams.CodeFourn = codeFourn;
    if (numBL !== undefined && numBL !== "") queryParams.NumBl = numBL;
    if (recepOuver !== undefined) queryParams.RecepOuver = recepOuver;
    if (recepTerm !== undefined) queryParams.RecepTerm = recepTerm;

    const queryString = new URLSearchParams(queryParams).toString();

    const data = await fetchServer(`/supplier/delivery?${queryString}`);
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static getFournBlLines = async (
    codeFourn?: string,
    numBL?: string,
    numColis?: string,
    idColis?: string,
    livrNbRef?: number,
    livrNb?: number
  ) => {
    const queryParams: { [key: string]: any } = {};

    if (codeFourn !== undefined && codeFourn !== "")
      queryParams.CodeFourn = codeFourn;
    if (numBL !== undefined && numBL !== "") queryParams.NumBl = numBL;
    if (numColis !== undefined) queryParams.numColis = numColis;
    if (idColis !== undefined) queryParams.idColis = idColis;
    if (livrNbRef !== undefined) queryParams.livrNbRef = livrNbRef;
    if (livrNb !== undefined) queryParams.livrNb = livrNb;

    const queryString = new URLSearchParams(queryParams).toString();

    const data = await fetchServer(
      `/supplier/delivery/package/lines?${queryString}`
    );
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static getFournOrders = async (
    filters: SupplierOrderRequestFilter,
    pagination?: CommonPaginationRequest
  ) => {
    const data = await fetchServer("/supplier/order", {
      method: "POST",
      body: JSON.stringify({
        orderBy: pagination?.orderBy || "dateCde",
        orderDir: pagination?.orderDir || "DESC",
        pageNumber: pagination?.pageNumber || 1,
        pageSize: pagination?.pageSize || 15,
        filters: { ...filters },
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static getFournOrderLines = async (numCde: string) => {
    const data = await fetchServer(`/supplier/order/lines?NumCde=${numCde}`);
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static createRetour = async (retourInfos: ReturnType, codeLieu: string) => {
    const data = await fetchServer("/supplier/return/preparation", {
      method: "PUT",
      body: JSON.stringify({
        codeLieuParQui: codeLieu,
        codeLieuPourQui: codeLieu,
        codeLieuExpe: codeLieu,
        codeLieuTrait: codeLieu,
        eaN13: retourInfos.id,
        qte: retourInfos.qteRetModif,
        codeMotifRet: retourInfos.motif,
        codeFourn: retourInfos.codeFourn,
        panie: retourInfos.basket,
        demandeAut: retourInfos.demandeAut,
      }),
    });
    if (data.status !== 204) {
      throw new Error("Erreur lors de la création de retour");
    }
    return true;
  };

  static createOrder = async (orderInfos: CommandType, codeLieu: string) => {
    const data = await fetchServer("/supplier/order/preparation", {
      method: "PUT",
      body: JSON.stringify({
        codeLieu: codeLieu,
        eaN13: orderInfos.id,
        qte: orderInfos.qteOrdered,
        codeModeExpe: orderInfos.codeModeExpe,
        codeFourn: orderInfos.codeFourn,
        panie: orderInfos.basket,
        tauxNet: 0,
        tauxNetNet: 0,
        tauxAs: 0,
      }),
    });
    if (data.status !== 204) {
      throw new Error("Erreur lors de la création de commande");
    }
    return true;
  };
  static getReassortLines = async (
    modeGestion: string,
    codeTypeProduct?: string,
    pagination?: CommonPaginationRequest
  ) => {
    const data = await fetchServer("/supplier/reassort", {
      method: "POST",
      body: JSON.stringify({
        orderBy: pagination?.orderBy || "ean13",
        orderDir: pagination?.orderDir || "DESC",
        pageNumber: pagination?.pageNumber || 1,
        pageSize: pagination?.pageSize || 15,
        modeGestion: modeGestion,
        codeTypeProduct: codeTypeProduct || "",
      }),
    });

    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return {
      items: result.items,
      pagination: {
        pageNumber: result.pageNumber,
        pageSize: result.pageSize,
        pageCount: result.pageCount,
        totalRows: result.totalRows,
        rowsReturned: result.rowsReturned,
      },
    } as {
      items: ReassortProductLine[];
      pagination: CommonPagination;
    };
  };

  static updateReassortLine = async (id: string, decis: string) => {
    const data = await fetchServer("/supplier/reassort", {
      method: "PATCH",
      body: JSON.stringify({ id: id, decis: decis }),
    });

    if (data.status !== 204) {
      const result = await data.json();
      throw new Error(result.title);
    }
    return true;
  };

  static getOrderPreparationHeader = async (
    codeTypeProd?: string,
    pourQui?: string,
    niv?: boolean
  ) => {
    const queryParams: { [key: string]: any } = {};

    if (codeTypeProd !== undefined && codeTypeProd !== "")
      queryParams.codeTypeProd = codeTypeProd;
    if (pourQui !== undefined && pourQui !== "")
      queryParams.CodeLieuPourQui = pourQui;
    if (niv !== undefined) queryParams.niv = niv;

    const queryString = new URLSearchParams(queryParams).toString();
    const data = await fetchServer(
      `/supplier/order/preparation/grp?${queryString}`,
      {
        method: "GET",
      }
    );
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as SupplierOrderPrepHeader[];
  };
  static deleteOrderPreparationHeader = async (req: PrepOrderRequest) => {
    const data = await fetchServer("/supplier/order/preparation/", {
      method: "DELETE",
      body: JSON.stringify({
        req,
      }),
    });
    const result = await data.json();
    if (data.status !== 204) {
      throw new Error(result.title);
    }
    return result;
  };

  static getOrderPreparationLines = async (
    codeTypeProd?: string,
    pourQui?: string,
    niv?: boolean,
    codeFourn?: string,
    panie?: string,
    codeModeExpe?: string,
    codeLieuLivr?: string
  ) => {
    const queryParams: { [key: string]: any } = {};
    if (codeTypeProd !== undefined && codeTypeProd !== "")
      queryParams.codeTypeProd = codeTypeProd;
    if (pourQui !== undefined && pourQui !== "") queryParams.pourQui = pourQui;
    if (niv !== undefined) queryParams.niv = niv;
    if (codeFourn !== undefined && codeFourn !== "")
      queryParams.codeFourn = codeFourn;
    if (panie !== undefined && panie !== "") queryParams.panie = panie;
    if (codeModeExpe !== undefined && codeModeExpe !== "")
      queryParams.codeModeExpe = codeModeExpe;
    if (codeLieuLivr !== undefined && codeLieuLivr !== "")
      queryParams.codeLieuLivr = codeLieuLivr;
    const queryString = new URLSearchParams(queryParams).toString();

    const data = await fetchServer(
      `/supplier/order/preparation/grp/lines?${queryString}`,
      {
        method: "GET",
      }
    );

    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as SupplierOrderPrepLine[];
  };

  static deleteOrderPreparationLine = async (
    codeFaitGener: string,
    codeLieuParQui: string,
    codeLieuPourQui: string,
    ean13: string,
    idFaitGener: string,
    numBroui: string,
    seq: number
  ) => {
    const data = await fetchServer("/supplier/order/preparation", {
      method: "DELETE",
      body: JSON.stringify({
        codeFaitGener: codeFaitGener,
        codeLieuParQui: codeLieuParQui,
        codeLieuPourQui: codeLieuPourQui,
        ean13: ean13,
        idFaitGener: idFaitGener,
        numBroui: numBroui,
        seq: seq,
      }),
    });
    const result = await data.json();
    if (data.status !== 204) {
      throw new Error(result.title);
    }
    return result;
  };
}
