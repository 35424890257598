import { Button } from "@atoms/button/button";
import { Checkbox } from "@atoms/input/input-checkbox";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { InfoSmall } from "@atoms/text";
import { CheckCircleIcon, PrinterIcon, XIcon } from "@heroicons/react/outline";
import { Form } from "@molecules/form";
import { SearchFormFieldType } from "@molecules/form/types";
import { useState } from "react";
import toast from "react-hot-toast";
import { atom, useRecoilState } from "recoil";

type SendOrderModalType = {
  open: boolean;
  order?: any;
};

export const SendOrderModal = atom<SendOrderModalType>({
  key: "SendOrderModalAtom",
  default: { open: false },
});

export const SendSupplierOrderModal = () => {
  const [sendOrderModal, setSendOrderModal] = useRecoilState(SendOrderModal);
  const [edi, setEdi] = useState(false);

  const handleClose = () => {
    setSendOrderModal({ open: false, order: undefined });
  };

  const mainFormPart: SearchFormFieldType[] = [
    {
      key: "fourn",
      label: "Fournisseur",
      //type: "text",
      alwaysVisible: true,
      render: (v, vs) => {
        return <InfoSmall>{`CODE FOURN`}</InfoSmall>;
      },
    },
    {
      key: "pourQui",
      label: "Livré à",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "exp",
      label: "Exp.",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "panie",
      label: "Panier",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "refer",
      label: "Référ. opé",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "taum",
      label: "TAUM",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "typeAchat",
      label: "Type achat",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "livPrev",
      label: "Livraison prév. ",
      type: "date",
      alwaysVisible: true,
    },
    {
      key: "ref",
      label: "Référence",
      type: "text",
      alwaysVisible: true,
    },
  ];

  const ediFormPart: SearchFormFieldType[] = [
    {
      key: "fourn",
      label: "Fournisseur",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "parQui",
      label: "Lieu émission",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "codeGroupe",
      label: "Code groupe",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "statu",
      label: "Statut",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "serv",
      label: "Serveur",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "trans",
      label: "Transmission",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "form",
      label: "Format",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "fax",
      label: "Fax",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "mail",
      label: "e-mail",
      type: "text",
      alwaysVisible: true,
    },
  ];

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={sendOrderModal.open}
      onClose={() => handleClose()}
    >
      <ModalContent title={"Pramètres d'envoi de commande(s)"}>
        <div className="flex flex-col gap-2 md:gap-2">
          <Form
            readonly={true}
            fields={mainFormPart}
            value={{ fourn: "truc" }}
            onChange={() => {}}
          />
          <Checkbox value={edi} onChange={() => setEdi(!edi)} label="EDI" />
          {edi && (
            <Form
              //readonly={true}
              fields={ediFormPart}
              value={{}}
              onChange={() => {}}
            />
          )}
          <div className="w-full flex justify-between mt-4">
            <Button
              theme="danger-outlined"
              onClick={() => handleClose()}
              icon={(p) => <XIcon {...p} />}
            >
              Retour
            </Button>
            <Button
              theme="secondary"
              onClick={() => {
                toast("Fonctionnalité bientôt disponible !");
              }}
              icon={(p) => <PrinterIcon {...p} />}
            >
              Imprimer brouillard
            </Button>
            <Button
              theme="valid"
              onClick={async () => {
                toast.success("Fonctionnalité bientôt disponible !");
                handleClose();
              }}
              icon={(p) => <CheckCircleIcon {...p} />}
            >
              Envoi de commande(s)
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
