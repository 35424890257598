import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { Shortcut } from "@features/utils/shortcuts";
import { MinusIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { NumericFormat } from "react-number-format";
import { useContext, useEffect, useState, FC } from "react";
import { ScanContext } from "@views/scan-provider";

interface InputCounterProps {
  min?: number;
  max?: number;
  confirmOnZero?: boolean;
  deleteIconOnZero?: boolean;
  value: number;
  onChange: (value: number) => void;
  onDelete?: () => void;
  size?: "sm" | "md" | "lg";
  id?: string;
  shortcutAdd?: Shortcut[];
  shortcutRemove?: Shortcut[];
  disabled?: boolean;
}

export const InputCounter: FC<InputCounterProps> = (props) => {
  const [value, setValue] = useState<string>(`${props.value}`);
  const context = useContext(ScanContext);

  useEffect(() => {
    setValue(`${props.value}`);
  }, [props.value]);

  const onChange = (value: number | string) => {
    if (typeof value === "number") {
      props.onChange(
        Math.max(
          props.min === undefined ? 0 : props.min,
          Math.min(
            props.max === undefined ? Number.MAX_SAFE_INTEGER : props.max,
            value
          )
        )
      );
    } else {
      setValue(value);
    }
  };

  const size = props.size || "sm";

  return (
    <div
      className="flex flex-row"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {props.value > 1 || !props.deleteIconOnZero || !props.confirmOnZero ? (
        <Button
          disabled={
            props.disabled ||
            (props.min && `${value}` === `${props.min}`) ||
            (props.min && props.value < props.min) ||
            props.min === props.value ||
            false
          }
          size={size}
          theme={
            props.deleteIconOnZero && `${value}` === "1" ? "danger" : "primary"
          }
          className="shrink-0 z-9"
          icon={(p) =>
            (props.deleteIconOnZero && `${value}` === "1") ||
            (props.deleteIconOnZero &&
              props.min &&
              `${value}` === `${props.min}`) ? (
              <TrashIcon {...p} />
            ) : (
              <MinusIcon {...p} />
            )
          }
          onClick={(e) => {
            e.stopPropagation();
            onChange(props.value - 1);
          }}
          shortcut={props.shortcutRemove}
        />
      ) : (
        <ButtonConfirm
          size={size}
          disabled={props.disabled}
          theme={props.deleteIconOnZero ? "danger" : "primary"}
          className="shrink-0 z-10"
          confirmTitle="Suppression d'un article"
          confirmMessage="Voulez-vous supprimer cet article de votre panier ?"
          confirmButtonText="Oui"
          cancelButtonText="Non"
          icon={(p) =>
            props.deleteIconOnZero ? <TrashIcon {...p} /> : <MinusIcon {...p} />
          }
          onClick={() => {
            onChange(props.value - 1);
            props.onDelete && props.onDelete();
          }}
          shortcut={props.shortcutRemove}
        />
      )}
      <NumericFormat
        id={props.id}
        value={value}
        allowNegative={false}
        disabled={props.disabled}
        max={props.max || 999}
        onFocus={(e) => {
          context.setSaveFocused &&
            context.setSaveFocused(props.value.toString());
          (e.target as HTMLInputElement).select();
        }}
        onBlur={(e) => {
          if (context?.saveFocused && context?.scanData) {
            onChange(+context.saveFocused);
            setValue(context.saveFocused);
          } else {
            const inputValue = Number(e.target.value);

            const newValue =
              isNaN(inputValue) ||
              e.target.value === "" ||
              (props.min !== undefined && inputValue < props.min)
                ? props.min ?? 1
                : inputValue;

            onChange(newValue);
            setValue(newValue.toString());
          }
          context?.setSaveFocused?.(null);
        }}
        onChange={(e) => {
          e.stopPropagation();
          const newValue = e.currentTarget.value;
          setValue(newValue);
          onChange(newValue === "" ? "" : +newValue);
        }}
        isAllowed={(values) => {
          const { floatValue } = values;
          if (floatValue === undefined) return true;
          const max = props.max !== undefined ? props.max : 999;
          return floatValue <= max;
        }}
        className="min-w-[25%] !border-blue-500 !border !border-solid  dark:bg-slate-900 dark:border-slate-700 dark:text-white px-0 py-0 text-center -mx-px"
      />
      <Button
        size={size}
        disabled={
          props.disabled ||
          (props.max && `${value}` === `${props.max}`) ||
          (props.max && props.value > props.max) ||
          props.max === props.value ||
          false
        }
        className="shrink-0"
        icon={(p) => <PlusIcon {...p} />}
        onClick={(e) => {
          e.stopPropagation();
          onChange(props.value + 1);
        }}
        shortcut={props.shortcutAdd}
      />
    </div>
  );
};
