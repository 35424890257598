import { CommonPagination } from "@features/types";
import { SupplierOrder, SupplierOrderPrepHeader } from "../types";
import { atom } from "recoil";
import { persistAtom } from "@features/utils/hooks/use-local-storage";

export const SupplierOrderList = atom<{
  pagination: CommonPagination;
  items: SupplierOrder[];
}>({
  key: "SupplierOrderList",
  default: {
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      pageCount: 0,
      totalRows: 0,
      rowsReturned: 0,
    },
    items: [] as SupplierOrder[],
  },
});

export const SelectedSupplierPrepHeader = persistAtom<SupplierOrderPrepHeader>(
  "user",
  {
    key: "SupplierOrderList",
    default: {} as SupplierOrderPrepHeader,
  }
);
