import Env from "@config/environment";
import { parseTextToDictionary } from "@features/utils/divers";
import { fetchServer } from "@features/utils/fetch-server";
import React, { useEffect } from "react";

const VersioningPage: React.FC = () => {
  useEffect(() => {
    const getInfos = async () => {
      try {
        const response = await fetchServer("/cashflo/techinformation", {
          method: "GET",
        });

        if (response.status !== 200) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.text();
        const dict = parseTextToDictionary(result);

        const jsonData = {
          "FRONT Version": Env.version,
          ...dict,
        };
        const jsonString = JSON.stringify(jsonData, null, 2);
        const blob = new Blob([jsonString], { type: "application/json" });
        const url = URL.createObjectURL(blob);

        window.location.href = url;

        return () => {
          URL.revokeObjectURL(url);
        };
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    getInfos();
  }, []);

  return null;
};

export default VersioningPage;
