import { BookRender } from "@atoms/book";
import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { Checkbox } from "@atoms/input/input-checkbox";
import { InputCounter } from "@atoms/input/input-counter";
import { InputFormat } from "@atoms/input/input-format";
import { Base, BaseBoldSmall, BaseSmall, Info, InfoSmall } from "@atoms/text";
import { ProductsApiClient } from "@features/products/api-client/api-client";
import { Book } from "@features/products/types";
import { ReceptionApiClient } from "@features/reception/api-client/api-client";
import { SelectedItemReception } from "@features/reception/state/store";
import { useReception } from "@features/reception/state/use-reception";
import {
  ReceptionLine,
  ReceptionSearchResult,
  ReceptionUnit,
} from "@features/reception/types";
import { ROUTES } from "@features/routes";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { SupplierOrder, SupplierPackage } from "@features/supplier/types";
import { getCommonProductTooltip } from "@features/utils/format/strings";
import {
  ArrowSmRightIcon,
  CurrencyEuroIcon,
  InformationCircleIcon,
  TableIcon,
  TagIcon,
  XIcon,
} from "@heroicons/react/outline";
import { GenericCard } from "@molecules/cards";
import { CommonConfirmModalAtom } from "@molecules/confirm-modal";
import { AffectationCmdClientModalAtom } from "@views/client/reception/receptions-modals/affection-commande-client-modal";
import { ListSupplierProductsModalAtom } from "@views/client/reception/receptions-modals/list-supplier-products-modal";
import { PrintLabelsModal } from "@views/client/reception/receptions-modals/print-labels-modal";
import { ReceptionInfoModalAtom } from "@views/client/reception/receptions-modals/reception-info-modal";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  FaBoxOpen,
  FaBox,
  FaWarehouse,
  FaExclamationTriangle,
} from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";

export const getTypeLibele = (item: ReceptionSearchResult) => {
  if (item.numBL !== "") return { label: "Bon de livraison", value: "BL" };
  if (item.numColis !== "") return { label: "Colis", value: "COLIS" };
  if (item.numCde !== "") return { label: "Commande", value: "CDE" };
  return { label: "Fournisseur", value: "FOURN" };
};

export const getLibel = (item: ReceptionUnit) => {
  if (item.listReceiptSupplierOrder) {
    return item.listReceiptSupplierOrder.length > 1
      ? `${item.typeRecepLibel} (${item.listReceiptSupplierOrder[0].numCde} ...)`
      : `${item.typeRecepLibel} (${item.listReceiptSupplierOrder[0].numCde})`;
  } else {
    return item.typeRecepLibel;
  }
};

// export const ReceptionCard = (props: { item: ReceptionUnit }) => {
//   const setReceptionInfoModal = useSetRecoilState(ReceptionInfoModalAtom);
//   const setConfirmModal = useSetRecoilState(CommonConfirmModalAtom);
//   const { changeSelectedReception, deleteReceipt } = useReception();
//   const navigate = useNavigate();
//   return (
//     <div className="relative">
//       <div className="group flex flex-col h-26 relative bg-amber-500 ">
//         <div className="flex flex-row gap-1 h-8 justify-start rounded-lg mx-2 mt-2 group-hover:mx-4 bg-lime-400 ">
//           <div className="flex flex-col justify-center items-end w-6 h-6">
//             <BookRender src="" productType={props.item.modeGest} />
//           </div>
//           <BaseBoldSmall className="group-hover:underline whitespace-nowrap overflow-hidden text-ellipsis cursor-text bg-red-400">
//             {props.item.codeFourn} - {props.item.numBL}
//           </BaseBoldSmall>
//         </div>
//         <div className="flex flex-col h-full w-full mx-4 mb-2 rounded-lg bg-blue-500">
//           <Info>{props.item.indicFactFourn}</Info>
//           <Info>Réception: {props.item.numRecep}</Info>
//           <Info>{getLibel(props.item)}</Info>
//           <Info>{props.item.nbrColis} colis</Info>
//         </div>

//         {/* <div
//           className="absolute bg-primary p-1 rounded-tl-lg rounded-br-lg bottom-0 right-0 transition duration-200 flex items-center justify-center text-blue-600 bg-blue-100"
//           data-tooltip="Accéder à la réception en cours"
//         >
//           <ArrowSmRightIcon className="w-5 h-5 group-hover:w-6 group-hover:h-6" />
//         </div> */}
//         <div className="flex flex-col gap-2">
//           <Button></Button>
//           <Button></Button>
//           <Button></Button>
//         </div>
//       </div>
//       {/* {!props.item.trait && (
//         <div
//           id="delete-reception"
//           className="absolute bg-primary p-1 rounded-tr-lg rounded-bl-lg top-10 right-0 transition duration-200 flex items-center justify-center text-white bg-rose-400"
//           onClick={(event) => {
//             event.stopPropagation();
//             changeSelectedReception(props.item);
//             navigate(
//               ROUTES.OngoingReception.replace(/:numRecep/, props.item.numRecep)
//             );
//             setConfirmModal({
//               theme: "danger",
//               icon: () => (
//                 <FaExclamationTriangle className="text-red-600 h-6 w-6" />
//               ),
//               open: true,
//               title: "Supprimer la réception",
//               message: "Voulez vous supprimer cette réception",
//               onAccept: async () => {
//                 if (props.item.qteRecu > 0)
//                   toast.error(
//                     "Cette réception ne peut être supprimée car une ou plusieurs ligne(s) ont déja été réceptionnée(s)"
//                   );
//                 else {
//                   await deleteReceipt(props.item);
//                 }
//               },
//             });
//           }}
//         >
//           <XIcon
//             className="w-5 h-5 hover:w-6 hover:h-6"
//             data-tooltip="Supprimer la réception"
//           />
//         </div>
//       )}
//       <div
//         id="info"
//         className="absolute bg-primary p-1 rounded-tr-lg rounded-bl-lg top-0 right-0 transition duration-200 flex items-center justify-center text-blue-600 bg-blue-100"
//         onClick={(e) => {
//           e.stopPropagation();
//           changeSelectedReception(props.item);
//           setReceptionInfoModal(true);
//         }}
//       >
//         <InformationCircleIcon
//           className="w-5 h-5 hover:w-6 hover:h-6"
//           data-tooltip="Informations sur la réception"
//         />
//       </div> */}
//     </div>
//   );
// };

export const ReceptionCard = (props: { item: ReceptionUnit }) => {
  const setReceptionInfoModal = useSetRecoilState(ReceptionInfoModalAtom);
  const setConfirmModal = useSetRecoilState(CommonConfirmModalAtom);
  const { changeSelectedReception, deleteReceipt } = useReception();
  const navigate = useNavigate();
  return (
    <div className="h-full w-full flex">
      <div className="grow flex flex-col ">
        <div className="flex flex-row gap-1 h-8 justify-start rounded-lg mx-2 mt-2 group-hover:mx-4">
          <div className="flex flex-col justify-center items-end w-6 h-6">
            <BookRender src="" productType={props.item.modeGest} />
          </div>
          <BaseBoldSmall className="group-hover:underline whitespace-nowrap overflow-hidden text-ellipsis cursor-text">
            {props.item.codeFourn} - {props.item.numBL}
          </BaseBoldSmall>
        </div>
        <div className="flex flex-col h-full w-full mx-4 mb-2 rounded-lg">
          <Info>{props.item.indicFactFourn}</Info>
          <Info>Réception: {props.item.numRecep}</Info>
          <Info>{getLibel(props.item)}</Info>
          <Info>{props.item.nbrColis} colis</Info>
        </div>
      </div>
      <div id="help" className="flex flex-col justify-between mx-x">
        <Button
          size="sm"
          theme="danger"
          icon={({ className }) => <XIcon className={className} />}
          onClick={(event) => {
            event.stopPropagation();
            changeSelectedReception(props.item);
            navigate(
              ROUTES.OngoingReception.replace(/:numRecep/, props.item.numRecep).replace(/:clotStep/, "0")
            );
            setConfirmModal({
              theme: "danger",
              icon: () => (
                <FaExclamationTriangle className="text-red-600 h-6 w-6" />
              ),
              open: true,
              title: "Supprimer la réception",
              message: "Voulez vous supprimer cette réception",
              onAccept: async () => {
                if (props.item.qteRecu > 0)
                  toast.error(
                    "Cette réception ne peut être supprimée car une ou plusieurs ligne(s) ont déja été réceptionnée(s)"
                  );
                else {
                  await deleteReceipt(props.item);
                }
              },
            });
          }}
        />
        <Button
          size="sm"
          icon={({ className }) => (
            <InformationCircleIcon className={className} />
          )}
          onClick={(e) => {
            e.stopPropagation();
            changeSelectedReception(props.item);
            setReceptionInfoModal(true);
          }}
        />
        <Button
          size="sm"
          icon={({ className }) => <ArrowSmRightIcon className={className} />}
        />
      </div>
    </div>
  );
};

export const SupplierOrderCard = (props: { item: SupplierOrder }) => {
  const { getFournOrderLines } = useSuppliers();
  const setListSupplierProductsModal = useSetRecoilState(
    ListSupplierProductsModalAtom
  );
  return (
    <GenericCard
      title={`Commande - ${props.item.numCde}`}
      //checkable={true}
      item={props.item}
      onInfoClick={async (colis) => {
        const products = await getFournOrderLines(props.item.numCde);
        setListSupplierProductsModal({ open: true, products: products });
      }}
    >
      <Info>{new Date(props.item.dateCreat).toISOString().split("T")[0]}</Info>
      <Info>{props.item.nbRef} références</Info>
      <Info>{props.item.nbArticle} articles</Info>
    </GenericCard>
  );
};

export const PackageCard = (props: { item: SupplierPackage }) => {
  const { getFournBlLines } = useSuppliers();
  const setListSupplierProductsModal = useSetRecoilState(
    ListSupplierProductsModalAtom
  );

  return (
    <GenericCard
      title={`Colis ${props.item.numBL}`}
      item={props.item}
      onInfoClick={async (colis) => {
        const products = await getFournBlLines(
          colis.codeFourn,
          colis.numBL,
          colis.numColis,
          colis.idColis
        );
        setListSupplierProductsModal({ open: true, products: products });
      }}
    >
      <Info>{props.item.livrNbRef} références</Info>
      <Info>{props.item.livrNb} articles</Info>
    </GenericCard>
  );
};

export const ReceptionSearchCard = (props: { line: ReceptionSearchResult }) => {
  const navigate = useNavigate();
  const libele = getTypeLibele(props.line);
  return (
    <div
      className="flex flex-col grow h-20"
      onClick={() => {
        navigate(ROUTES.OngoingReception.replace(/:clotStep/, "0"));
        //navigate(ROUTES.SupplierPage);
      }}
    >
      <div className="flex flex-row h-8 justify-start bg-slate-300">
        <div className="flex flex-col justify-center">
          {libele.value === "FOURN" ? (
            <FaWarehouse className="w-full h-full text-lg p-1" />
          ) : props.line.recepOuver ? (
            <FaBoxOpen className="w-full h-full text-lg p-1" />
          ) : (
            <FaBox className="w-full h-full text-lg p-1" />
          )}
        </div>
        <div className="flex flex-col justify-center">
          <Base> - {libele.label}</Base>
        </div>
      </div>
      <div className="grow flex flex-col justify-center items-center">
        <Base>
          {props.line.numBL + props.line.numColis + props.line.numCde}{" "}
          {props.line.fourn !== "" ? `(${props.line.fourn})` : ""}
        </Base>
      </div>
    </div>
  );
};

export const SupplierPackageCard = (props: { line: any }) => {
  const libele = getTypeLibele(props.line);
  return (
    <div className="flex flex-col grow h-20" onClick={() => {}}>
      <div className="flex flex-row h-8 justify-start bg-blue-100">
        <div className="flex flex-col justify-center">
          {/* {props.line.numBL === "" &&
          props.line.numColis === "" &&
          props.line.numCde === "" ? (
            <FaWarehouse className="w-full h-full text-lg p-1" />
          ) : props.line.recepOuver ? (
            <FaBoxOpen className="w-full h-full text-lg p-1" />
          ) : (
            <FaBox className="w-full h-full text-lg p-1" />
          )} */}
        </div>
        <div className="flex flex-col justify-center">
          <Base> - {libele.label}</Base>
        </div>
        <div className="flex flex-col grow justify-center items-end">
          <Checkbox className="border-slate-500 mx-2" />
        </div>
      </div>
      <div className="flex grow">
        <div className="grow flex flex-col justify-center items-center">
          <Base>
            {props.line.numBL + props.line.numColis + props.line.numCde}{" "}
            {props.line.fourn !== "" ? `(${props.line.fourn})` : ""}
          </Base>
        </div>
        <div className="flex flex-col justify-center items-center mx-1">
          <Button
            theme="secondary"
            icon={({ className }) => (
              <InformationCircleIcon className={className} />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export const ReceptionProductActions = ({
  reception,
  product,
  shortcuts,
  size,
  autoFocusQuantity,
  lock,
  disabled,
  disableOnSelected,
}: {
  reception: ReceptionUnit;
  product: ReceptionLine;
  shortcuts?: boolean;
  size?: "lg" | "md" | "sm";
  autoFocusQuantity?: boolean;
  lock?: boolean;
  disabled?: boolean;
  disableOnSelected?: boolean;
}) => {
  size = size || "sm";

  const {
    selectedReception,
    receiptLines,
    loading,
    setReceiptLines,
    getRelatedOrders,
    handleCommit,
    getLines,
    getReceipt,
    changeSelectedReception,
  } = useReception();
  const [selectedItem, setSelectedItem] = useRecoilState(SelectedItemReception);

  const setAffectationCmdClient = useSetRecoilState(
    AffectationCmdClientModalAtom
  );

  const setPrintLabelsModal = useSetRecoilState(PrintLabelsModal);

  return (
    <>
      {(product.linePk !== "" && product.qteCdeCli > 0) ||
      (product.linePk === selectedItem?.linePk &&
        selectedItem?.forceRelatedOrders) ||
      (selectedItem &&
        product.linePk === selectedItem.linePk &&
        product.qteCdeCli > 0) ? (
        <Button
          className="shrink-0"
          size={size}
          onClick={async (e) => {
            e.stopPropagation();
            const orders = await getRelatedOrders(
              reception,
              product.eaN13,
              product.linePk,
              product.qteRecu
            );
            setAffectationCmdClient({
              open: true,
              receipt: reception,
              item: product,
              orders: orders,
              total: selectedItem?.qteRecu,
            });
          }}
          theme="secondary"
          data-tooltip="Affectations commandes clients"
          icon={({ className }) => <TableIcon className={className} />}
        />
      ) : null}
      {product.linePk !== "" && product.linePk !== selectedItem?.linePk && (
        <Button
          className="shrink-0"
          disabled={product.qteCdeCli > 0 || loading}
          size={size}
          onClick={(e) => {
            e.stopPropagation();
            setPrintLabelsModal({ open: true, item: product });
          }}
          theme="secondary"
          data-tooltip="Imprimer étiquettes"
          icon={({ className }) => <TagIcon className={className} />}
        />
      )}
      {selectedItem && selectedItem?.linePk === product.linePk && !disabled && (
        <div
          id={
            autoFocusQuantity && selectedItem?.eaN13 === product.eaN13
              ? "product-counter"
              : ""
          }
          style={{ maxWidth: 128, minWidth: 96 }}
        >
          <InputCounter
            size={size}
            confirmOnZero
            deleteIconOnZero
            disabled={disabled || selectedReception.trait}
            onDelete={async () => {
              await handleCommit({ ...product, qteRecu: 0 }, false);

              const indexP = receiptLines.items.findIndex(
                (p) => p.linePk === product.linePk
              );

              const tempTab = [...receiptLines.items].filter(
                (el) => receiptLines.items[indexP].linePk !== el.linePk
              );

              //const res = await getLines(reception);
              setReceiptLines({ ...receiptLines, items: tempTab });
              (document.activeElement as HTMLInputElement).blur();
              if (selectedItem) setSelectedItem(null);
              if (!selectedItem) {
                const updatedReception = await getReceipt(selectedReception);
                changeSelectedReception(updatedReception);
              }
            }}
            min={1}
            // disabled={
            //   disableOnSelected && selectedItem?.linePk === product.linePk
            // }
            value={product.qteRecu}
            onChange={async (value) => {
              if (value === product.qteRecu) return;
              if (product.linePk !== selectedItem?.linePk) {
                await handleCommit({ ...product, qteRecu: value }, false);

                const indexP = receiptLines.items.findIndex(
                  (p) => p.linePk === product.linePk
                );

                const tempTab =
                  value !== 0
                    ? [...receiptLines.items]
                    : [...receiptLines.items].filter(
                        (el) => receiptLines.items[indexP].linePk !== el.linePk
                      );

                if (value !== 0)
                  tempTab[indexP] = { ...product, qteRecu: value };
                if (value !== 0)
                  setReceiptLines({ ...receiptLines, items: tempTab });
                else {
                  const res = await getLines(reception);
                  setReceiptLines({ ...receiptLines, items: res.items });
                  (document.activeElement as HTMLInputElement).blur();
                }
              } else if (
                selectedItem &&
                selectedItem.linePk === product.linePk
              ) {
                setSelectedItem({
                  ...product,
                  qteRecu: value,
                  nbrEtiq: selectedItem && selectedItem.nbrEtiq > 0 ? value : 0,
                });
              }
            }}
          />
        </div>
      )}{" "}
      {selectedReception.modeGest &&
        selectedItem?.linePk !== product.linePk &&
        disabled && (
          <>
            {product.tooltip && product.tooltip.modeGest === "L" && (
              <ButtonConfirm
                confirmTitle={`Mettre à jour de la fiche article de ${product.desig}`}
                confirmMessage={`Vous êtes sur le point de modifier le fiche article de : ${product.desig} .Êtes-vous sûr de vouloir continuer ?`}
                onClick={async (e) => {
                  e.stopPropagation();
                  const resProduct = await ProductsApiClient.getProduct(
                    product.eaN13,
                    "book"
                  );
                  const resUpdate = await ProductsApiClient.updatePrice(
                    resProduct as Book,
                    product.codeLieu,
                    product.prixBase
                  );
                  if (resUpdate)
                    toast.success("Prix du produit mis à jour avec succès");
                }}
                theme="secondary"
                size="sm"
                //className="rounded-full"
                data-tooltip="MAJ prix de vente"
                icon={({ className }) => (
                  <CurrencyEuroIcon className={className} />
                )}
              />
            )}
            {!selectedReception.trait && (
              <ButtonConfirm
                confirmTitle={`Suppression d'une ligne de réception`}
                confirmMessage={`Vous êtes sur le point de supprimer la ligne de récéption de l'article: ${product.desig} .Êtes-vous sûr de vouloir continuer ?`}
                theme="danger"
                size="sm"
                onClick={async () => {
                  await handleCommit({ ...product, qteRecu: 0 }, false);

                  // const indexP = receiptLines.items.findIndex(
                  //   (p) => p.linePk === product.linePk
                  // );

                  // const tempTab = [...receiptLines.items].filter(
                  //   (el) => receiptLines.items[indexP].linePk !== el.linePk
                  // );

                  const res = await getLines(reception);
                  setReceiptLines({
                    pagination: {
                      pageNumber: res.pageNumber,
                      pageSize: res.pageSize,
                      pageCount: res.pageCount,
                      totalRows: res.totalRows,
                      rowsReturned: res.rowsReturned,
                    },
                    items: res.items,
                  });
                  //setReceiptLines({ ...receiptLines, items: tempTab });
                  (document.activeElement as HTMLInputElement).blur();
                  if (selectedItem) setSelectedItem(null);
                  if (!selectedItem) {
                    const updatedReception = await getReceipt(
                      selectedReception
                    );
                    changeSelectedReception(updatedReception);
                  }
                }}
                icon={({ className }) => <FaXmark className={className} />}
              />
            )}
          </>
        )}{" "}
    </>
  );
};

export default function ReceptionProductCard({
  reception,
  product,
  showBorderOnSelected,
  autoFocusQuantity,
  lock,
  disabled,
  disableOnselected,
}: {
  reception: ReceptionUnit;
  product: ReceptionLine;
  showBorderOnSelected?: boolean;
  disableOnselected?: boolean;
  disabled?: boolean;
  autoFocusQuantity?: boolean;
  lock?: boolean;
}) {
  const [tempItem, setTempItem] = useState(product);
  const [autoBlur, setAutoBlur] = useState(false);
  const [selectedItem, setSelectedItem] = useRecoilState(SelectedItemReception);
  const [oldSelectedValue, setOldSelectedValue] = useState<number>();

  const {
    receiptLines,
    getRelatedOrders,
    selectedReception,
    updateLine,
    setReceiptLines,
    valoLine,
  } = useReception();

  const blurCurrentInput = () => {
    const input = document.activeElement as HTMLInputElement;
    if (
      input.id === "prixBase" ||
      input.id === "tauxNet" ||
      input.id === "prixNetHt"
    )
      input.blur();
  };

  useEffect(() => {
    setTempItem({ ...product });
  }, [product]);

  //À factoriser
  const handleBlur = async (key: string, value: string) => {
    setAutoBlur(false);
    if (selectedItem?.linePk !== product.linePk) {
      const index = receiptLines.items.findIndex(
        (el) => el.linePk === product.linePk
      );

      if (index !== -1) {
        const orders = await getRelatedOrders(
          selectedReception,
          receiptLines.items[index].eaN13,
          receiptLines.items[index].linePk,
          receiptLines.items[index].qteRecu
        );
        const res = await updateLine(
          {
            ...product,
            [key]: isNaN(parseFloat(value))
              ? 0
              : parseFloat(value.replace(",", ".").replace(/[^0-9.]/g, "")),
          },
          orders
        );

        const resValo =
          (key === "prixBase" || "prixNetHt") && value === ""
            ? { tauxNet: product.tauxNet, netHT: 0 }
            : await ReceptionApiClient.valoLine(
                selectedReception,
                {
                  ...product,
                  [key]: isNaN(parseFloat(value))
                    ? 0
                    : parseFloat(
                        value.replace(",", ".").replace(/[^0-9.]/g, "")
                      ),
                },
                key
              );
        const indexP = receiptLines.items.findIndex(
          (p) => p.linePk === product.linePk
        );
        const tempTab = [...receiptLines.items];
        const saveTab = [...receiptLines.items];
        tempTab[indexP] = {
          ...product,
          [key]: isNaN(parseFloat(value))
            ? 0
            : parseFloat(value.replace(",", ".").replace(/[^0-9.]/g, "")),
          tauxNet: resValo.tauxNet,
          prixNetHt: resValo.netHT,
        };
        if (res) {
          setReceiptLines({ ...receiptLines, items: tempTab });
          setTempItem({
            ...product,
            [key]: +value,
          });
        } else setReceiptLines({ ...receiptLines, items: saveTab });
      }
    } else {
      setSelectedItem({
        ...selectedItem,
        [key]: isNaN(parseFloat(value))
          ? 0
          : parseFloat(value.replace(",", ".").replace(/[^0-9.]/g, "")),
      });
    }
  };

  const handleChange = async (key: string, value: string) => {
    valoLine(product, key, !value || isNaN(parseFloat(value)) ? "0" : value);
  };

  return (
    <div
      className={`flex grow h-52 relative ${
        showBorderOnSelected && product.linePk === selectedItem?.linePk && !lock
          ? "border-2 border-blue-600"
          : ""
      }`}
      onClick={() => {}}
    >
      <div className="shrink-0 h-full relative sm:w-32 w-24 py-2 flex justify-center border-r border-slate-200 dark:border-slate-800 bg-cover bg-center">
        <div className="px-2 flex items-center justify-center">
          <BookRender
            src={product.imageURL}
            productType={product.tooltip ? product.tooltip.modeGest : "P"}
          />
        </div>
      </div>
      <div className="grow flex flex-col p-2 w-full overflow-hidden ">
        <BaseSmall
          className="font-semibold whitespace-nowrap overflow-hidden text-ellipsis cursor-text"
          data-html={true}
          data-tooltip={
            product.tooltip && getCommonProductTooltip(product, product.tooltip)
          }
        >
          {product.desig}
        </BaseSmall>
        <BaseSmall
          className="block my-1 cursor-text"
          onClick={(e) => {
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            e.stopPropagation();
            const range = document.createRange();
            range.selectNode(e.currentTarget);
          }}
        >
          {product.eaN13}
        </BaseSmall>
        <div className="flex flex-col space-y-1 my-2">
          <div className="flex gap-1 items-center">
            <InputFormat
              autoSelect={true}
              id="prixBase"
              format="price"
              autoComplete="off"
              value={tempItem.prixBase}
              disabled={
                selectedItem === null ||
                selectedItem.linePk !== product.linePk ||
                disabled
              }
              size="sm"
              inputClassName="w-24"
              onBlur={(e) => {
                e.stopPropagation();
                if (oldSelectedValue !== parseFloat(e.target.value))
                  handleBlur("prixBase", e.target.value);
              }}
              onFocus={(e) => {
                e.stopPropagation();
                setOldSelectedValue(parseFloat(e.target.value));
              }}
              onClick={(e) => {
                e.stopPropagation();
                setAutoBlur(true);
              }}
              onChange={(e) => {
                handleChange("prixBase", e.target.value);
              }}
            />{" "}
            <InfoSmall className="w-full">
              {reception.modeGest === "L" ? "TTC" : "HT PU"}
            </InfoSmall>
          </div>
          <div className="flex gap-1 items-center ">
            <InputFormat
              id="tauxNet"
              format="percentage"
              autoComplete="off"
              autoSelect={true}
              value={product.tauxNet}
              disabled={
                selectedItem === null ||
                selectedItem.linePk !== product.linePk ||
                disabled
              }
              size="sm"
              inputClassName="w-24"
              onFocus={(e) => {
                e.stopPropagation();
                setOldSelectedValue(parseFloat(e.target.value));
              }}
              onBlur={(e) => {
                e.stopPropagation();
                if (oldSelectedValue !== parseFloat(e.target.value))
                  handleBlur(
                    "tauxNet",
                    parseFloat(e.target.value) > 100
                      ? "100"
                      : `${Math.abs(parseFloat(e.target.value))}`
                  );
              }}
              onClick={(e) => {
                e.stopPropagation();
                setAutoBlur(true);
              }}
              onChange={(e) => {
                handleChange(
                  "tauxNet",
                  parseFloat(e.target.value) > 100
                    ? "100"
                    : `${Math.abs(parseFloat(e.target.value))}`
                );
              }}
            />{" "}
            <InfoSmall className="w-full">remise</InfoSmall>
          </div>
          <div className="flex gap-1 items-center ">
            <InputFormat
              id="prixNetHt"
              format="price"
              autoSelect={true}
              autoComplete="off"
              value={product.prixNetHt}
              disabled={
                selectedItem === null ||
                selectedItem.linePk !== product.linePk ||
                disabled
              }
              size="sm"
              inputClassName="w-24"
              onFocus={(e) => {
                e.stopPropagation();
                setOldSelectedValue(parseFloat(e.target.value));
              }}
              onBlur={(e) => {
                e.stopPropagation();
                if (oldSelectedValue !== parseFloat(e.target.value))
                  handleBlur("prixNetHt", e.target.value);
              }}
              onClick={(e) => {
                e.stopPropagation();
                setAutoBlur(true);
              }}
              onChange={(e) => {
                handleChange("prixNetHt", e.target.value);
              }}
            />
            <InfoSmall className="w-full">HT PA</InfoSmall>{" "}
          </div>
          <Button
            className="hidden"
            onClick={(e) => {
              e.stopPropagation();
              if (!selectedItem || !(selectedItem.linePk === product.linePk))
                if (autoBlur) {
                  blurCurrentInput();
                } else {
                  setAutoBlur(true);
                }
            }}
          />
        </div>
        <div className="flex justify-around w-full">
          {disabled && (
            <div className="w-full flex flex-col justify-center">
              <BaseSmall className="w-full">Qte: {product.qteRecu}</BaseSmall>
            </div>
          )}

          <div className="flex space-x-2 justify-end w-full">
            <ReceptionProductActions
              product={product}
              reception={reception}
              size="sm"
              autoFocusQuantity={autoFocusQuantity}
              lock={lock}
              disableOnSelected={disableOnselected}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
      {selectedReception.modeGest === "L" &&
        selectedItem?.linePk === product.linePk &&
        !disabled && (
          <ButtonConfirm
            confirmTitle={`Mettre à jour de la fiche article de ${product.desig}`}
            confirmMessage={`Vous êtes sur le point de modifier le fiche article de : ${product.desig} .Êtes-vous sûr de vouloir continuer ?`}
            theme="secondary"
            className="absolute top-2 right-1 flex items-center justify-center rounded-full"
            onClick={async (e) => {
              e.stopPropagation();
              const resProduct = await ProductsApiClient.getProduct(
                product.eaN13,
                "book"
              );
              const resUpdate = await ProductsApiClient.updatePrice(
                resProduct as Book,
                product.codeLieu,
                product.prixBase
              );
              if (resUpdate)
                toast.success("Prix du produit mis à jour avec succès");
            }}
            data-tooltip="MAJ prix de vente"
            icon={({ className }) => <CurrencyEuroIcon className={className} />}
          />
        )}
    </div>
  );
}
