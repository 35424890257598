import { Modal, ModalContent } from "@atoms/modal/modal";
import { atom, useRecoilState } from "recoil";

type SearchProductModalType = {
  open: boolean;
  order?: any;
};

export const SearchProductModalAtom = atom<SearchProductModalType>({
  key: "SearchProductFournModalAtom",
  default: { open: false },
});

export const SearchProductModal = () => {
  const [serchProductModal, setSearchProductModal] = useRecoilState(
    SearchProductModalAtom
  );

  const handleClose = () => {
    setSearchProductModal({ open: false, order: undefined });
  };
  return (
    <Modal
      className="min-w-full min-h-[900px]" // s'assure que la modal ait au moins cette taille
      open={serchProductModal.open}
      onClose={() => handleClose()}
    >
      <ModalContent title={"Ajout produit"}>
        {" "}
        {/* Utilisation de flex pour étirer le contenu */}
        <div className="flex flex-col h-full w-full">
          <iframe
            className="flex-grow w-full bg-red-400 border-2 border-red-600"
            src={"http://localhost:3006/products"}
            title="Navigateur Simulé"
            style={{ border: "none", minHeight: "800px" }} // Ajout d'une hauteur minimale
          />
        </div>
      </ModalContent>
    </Modal>
  );
};
