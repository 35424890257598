import { BookRender } from "@atoms/book";
import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import Select from "@atoms/input/input-select";
import { Base, BaseSmall, Info, InfoSmall, Section, Title } from "@atoms/text";
import { OrderItem } from "@features/customer/type";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { useBasket } from "@features/products/state/use-basket";
import { useBaskets } from "@features/products/state/use-baskets";
import { ROUTES } from "@features/routes";
import {
  formatAmount,
  getCommonProductTooltip,
} from "@features/utils/format/strings";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import { Column } from "@molecules/table/table";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import _ from "lodash";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OrderProductCard from "./order-product-card";

const OrderReview = (props: {
  products: OrderItem[];
  onChange: (value: OrderItem[]) => void;
  readonly?: boolean;
  enableBuy?: boolean;
}) => {
  const [orderBy, setOrderBy] = useState<[number, string]>([0, "ASC"]);
  const { current } = useBaskets();
  const { current: currentShop } = useShopLocations();
  const {
    products: fullProducts,
    add,
    remove,
    changeStatu,
  } = useBasket(current || "");
  const columns = ["designation", "pxUnit", "qte", "total", "action"];
  const location = useLocation();
  // const [basketItems, setBasketItem] = useState<{ ean: string; qte: number }[]>(
  //   fullProducts.map((el) => {
  //     return { ean: el.product.id, qte: el.count };
  //   })
  // );
  const navigate = useNavigate();
  const allColumns: Column<OrderItem>[] = [
    {
      title: "",
      render: (orderItem, { responsive }) =>
        responsive && (
          <>
            <div className="w-10 print:hidden">
              <BookRender
                src={orderItem.product.imageURL}
                productType={orderItem.product.modeGest}
              />
            </div>
          </>
        ),
    },
    {
      title: "Article",
      orderable: true,
      render: (orderItem, { responsive }) =>
        responsive ? (
          <div
            className="w-full h-full flex flex-row text-right items-center"
            onClick={() => {
              navigate(
                ROUTES.Product.replace(/:ean/, orderItem.product.id)
                  .replace(/:type/, orderItem.product.modeGest)
                  .replace(
                    /:referGestion/,
                    orderItem.product.referGestion.toString()
                  )
              );
            }}
          >
            {/* <div className="flex justify-center h-full w-8 justify-center">
              <div className="h-full flex ">
                <BookRender
                  src={orderItem.product.imageURL || "/medias/placeholder.png"}
                />
              </div>
            </div> */}
            <div className="w-full h-full flex flex-col">
              <BaseSmall
                data-html={true}
                data-tooltip={getCommonProductTooltip(
                  orderItem.product,
                  orderItem.product.tooltip
                )}
              >
                {orderItem.product.designation}
              </BaseSmall>
              <InfoSmall>{orderItem.product.id}</InfoSmall>
              <InfoSmall className="print:hidden">
                {orderItem.product.qteDispoVenteFutur} dispo vente future (
                {orderItem.product.qteDispoVente} dispo vente,
                {orderItem.product.qtePrepCdePlusTrans} attendue
                commande/transfert (dont {orderItem.product.qteAffecCli}{" "}
                réservés clients))
              </InfoSmall>
            </div>
          </div>
        ) : (
          <div
            className="flex flex-row items-center space-x-4 cursor-pointer"
            onClick={() => {
              navigate(
                ROUTES.Product.replace(/:ean/, orderItem.product.id)
                  .replace(/:type/, orderItem.product.modeGest)
                  .replace(
                    /:referGestion/,
                    orderItem.product.referGestion.toString()
                  )
              );
            }}
          >
            <div className="w-10 print:hidden">
              <BookRender
                src={orderItem.product.imageURL}
                productType={orderItem.product.modeGest}
              />
            </div>

            <div className="flex flex-col">
              <Base
                className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
                data-tooltip={getCommonProductTooltip(
                  orderItem.product,
                  orderItem.product.tooltip
                )}
              >
                {orderItem.product.designation}
              </Base>
              <Info>{orderItem.product.id}</Info>
              <InfoSmall className="print:hidden">
                {orderItem.product.qteDispoVenteFutur} disponible à la vente
                future ({orderItem.product.qteDispoVente} disponible à la vente,
                {orderItem.product.qteCdeFournPlusTrans} attendus en commande et
                transfert (dont {orderItem.product.qteReservCli} réservé
                clients))
              </InfoSmall>
            </div>
          </div>
        ),
    },
    {
      title: "Prix TTC",
      orderable: true,
      render: (orderItem) => (
        <Base>
          {formatAmount(
            orderItem.pxUnit,
            currentShop?.devisSymb,
            currentShop?.devisNbDecim
          )}
        </Base>
      ),
    },
    {
      title: "Quant.",
      orderable: true,
      render: (orderItem) => (
        <div
          key={orderItem.qte}
          className="flex flex-col justify-center orderItems-center gap-1"
        >
          {props.readonly ? (
            <Base>{orderItem.qte}</Base>
          ) : (
            <div style={{ maxWidth: 128, minWidth: 96 }}>
              <InputCounter
                size={"md"}
                deleteIconOnZero
                value={orderItem.qte}
                onChange={(value) => {
                  const productID = fullProducts.findIndex(
                    (el) => el.product.id === orderItem.product.id
                  );
                  if (value > orderItem.qte) {
                    add(fullProducts[productID].product, value - orderItem.qte);
                  } else {
                    remove(
                      fullProducts[productID].product,
                      orderItem.qte - value
                    );
                  }
                  props.onChange(props.products);
                }}
              />
            </div>
          )}
        </div>
      ),
    },

    {
      title: "Total",
      orderable: true,
      render: (orderItem) => (
        <Base>
          {formatAmount(
            orderItem.pxUnit * orderItem.qte,
            currentShop?.devisSymb,
            currentShop?.devisNbDecim
          )}
        </Base>
      ),
    },
    {
      title: location.pathname.includes("basket") ? "Action" : "Statut",
      orderable: true,
      render: (orderItem) => {
        if (props.readonly) {
          return orderItem.statu;
        } else {
          return (
            <Select
              className="shrink-0"
              value={orderItem.statu}
              data-tooltip={`${orderItem.statu} - ${
                orderItem.statu === "ACO"
                  ? "À commander"
                  : orderItem.statu === "RES"
                  ? "Réservation client"
                  : "Prise sur stock"
              }`}
              onChange={(e) => {
                e.stopPropagation();
                const productID = fullProducts.findIndex(
                  (el) => el.product.id === orderItem.product.id
                );

                changeStatu(
                  fullProducts[productID].product,
                  orderItem.qte,
                  e.target.value as "ACO" | "RES" | "STO"
                );

                props.onChange(props.products);
              }}
              /* onChange={(e) => {
              props.onChange(
                props.products.map((p) => {
                  if (p.product.id === orderItem.product.id) {
                    return { ...p, statu: e.target.value };
                  }
                  return p;
                })
              );
            }} */
            >
              <option value="ACO">ACO - À commander</option>
              <option value="RES">RES - Réservation client</option>
              <option value="STO">PS - Prise sur stock</option>
            </Select>
          );
        }
      },
    },
  ];

  if (props.enableBuy) {
    allColumns.push({
      thClassName: "print:hidden",
      className: "print:hidden",
      headClassName: "print:hidden",
      title: "Ajouter au panier",
      orderable: false,
      render: (orderItem) => {
        const basketSize = fullProducts.find(
          (e) => e.product.id === orderItem.product.id
        )?.count;
        if (!basketSize) {
          return (
            <div className="w-full flex justify-center print:hidden">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  //add(orderItem.product, orderItem.qte); --> si on décide d'en ajouter autant que dans la commande
                  add(orderItem.product, 1);
                }}
                icon={(p) => <ShoppingCartIcon {...p} />}
              />{" "}
            </div>
          );
        } else {
          return (
            <InputCounter
              size="md"
              confirmOnZero
              deleteIconOnZero
              value={basketSize || 0}
              onChange={(value) => {
                if (value > basketSize!)
                  add(orderItem.product, value - basketSize!);
                else remove(orderItem.product, basketSize! - value);
              }}
            />
          );
        }
      },
    });
  }

  return (
    <div className="grow">
      <TableGridSwitch
        gridMode={false}
        data={_.sortBy(props.products, (orderItem) => {
          const orderColumn = columns[orderBy[0]];
          let v = (orderItem as any)[orderColumn];
          if (orderColumn === "total") v = orderItem.pxUnit * orderItem.qte;
          return orderBy[1] === "ASC" ? v : -v;
        })}
        total={props.products.length}
        initialPagination={{
          order: orderBy[1] === "ASC" ? "ASC" : "DESC",
          orderBy: orderBy[0],
          page: 0,
          perPage: 0,
        }}
        showPagination={false}
        onRequestData={async ({ orderBy, order }) => {
          setOrderBy([orderBy || 0, order || "ASC"]);
        }}
        tableColumns={allColumns}
        renderGrid={(p) => (
          <OrderProductCard orderItem={p} editable={!props.readonly} />
        )}
        onFetchExportData={async () => {
          return props.products.map((el, key) => {
            return { id: key, ...el };
          });
        }}
        gridClassName={
          "grid gap-4 grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3"
        }
        subObjectHeader={{
          product: ["id", "designation"],
        }}
      />
      <div className="grid grid-cols-2 gap-4 mt-2 px-2">
        <div className="text-start flex flex-col justify-center">
          <Section>
            {props.products.reduce((sum, el) => sum + el.qte, 0) || 0} articles
          </Section>
        </div>
        <div className="flex flex-col justify-center text-right">
          <Title>
            {formatAmount(
              props.products.reduce((sum, el) => sum + el.qte * el.pxUnit, 0) ||
                0,
              currentShop?.devisSymb,
              currentShop?.devisNbDecim
            )}
          </Title>
        </div>
      </div>
    </div>
  );
};

export default OrderReview;
