import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { useSetRecoilState } from "recoil";
import { SendOrderModal } from "../send-order-modal";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { Base, Info } from "@atoms/text";
import { SupplierOrderPrepLine } from "@features/supplier/types";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { SearchProductModalAtom } from "../search-product-modal";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";

export const SupplierSendOrderLinePage = () => {
  const setSendOrderModal = useSetRecoilState(SendOrderModal);
  const [orderLines, setOrderLines] = useState<SupplierOrderPrepLine[]>([]);
  const { getOrderPreparationLines, selectedPrepHeader } = useSuppliers();
  const setSearchProductModal = useSetRecoilState(SearchProductModalAtom);
  const [gridMode, setGridMode] = usePersistedState(
    "user",
    "supplier-send-order-grid-mode",
    {
      activated: false,
    }
  );
  const handleClose = () => {
    setSearchProductModal({ open: false, order: undefined });
  };

  useControlledEffect(() => {
    const messageHandler = async (event: MessageEvent) => {
      if (event.data.action === "closeModal") {
        handleClose();
        if (event.data.product) {
          //createOrder(product as ProductType)
        }
      }
    };

    window.addEventListener("message", messageHandler);

    // Nettoyage de l'événement lors du démontage du composant
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  const orderColumns: Column<SupplierOrderPrepLine>[] = [
    {
      title: "Article",
      id: "",
      render: (p) => (
        <div className="flex flex-col">
          <Base>{p.ean13}</Base>
          <Info>{p.desig}</Info>
        </div>
      ),
    },
    {
      title: "Rayon",
      id: "codeRayon",
      render: (p) => <Base>{p.codeRayon}</Base>,
    },
    {
      title: "Éditeur",
      id: "codeEdit",
      render: (p) => <Base>{p.codeEdit}</Base>,
    },
    {
      title: "PU TTC",
      id: "raisSocial",
      render: (p) => <Base>{p.prixBase}</Base>,
    },
    {
      title: "Orig.",
      id: "orig",
      render: (p) => <Base>{p.orig}</Base>,
    },
    {
      title: "Fournisseur",
      id: "codeFounr",
      render: (p) => <Base>{p.codeFourn}</Base>,
    },
    {
      title: "Exp.",
      id: "codeModeExpe",
      render: (p) => <Base>{p.codeModeExpe}</Base>,
    },
    {
      title: "Opé.",
      id: "codeOpe",
      render: (p) => <Base>{p.codeOpe}</Base>,
    },
    {
      title: "Panier",
      id: "panie",
      render: (p) => <Base>{p.panie}</Base>,
    },
    {
      title: "Quantité",
      id: "qte",
      render: (p) => <Base>{p.qte}</Base>,
    },
    {
      title: "Dispo. vente",
      id: "qteDispoVente",
      render: (p) => <Base>{p.qteDispoVente}</Base>,
    },
  ];

  return (
    <Page>
      <>
        <div className="flex gap-2">
          <Button onClick={() => setSendOrderModal({ open: true })}>
            Commande fournisseur: NUM COMMANDE
          </Button>
          <Button
            theme="outlined"
            icon={(p) => <PlusCircleIcon {...p} />}
            onClick={() => setSearchProductModal({ open: true })}
          >
            Ajouter un article
          </Button>
        </div>
        <TableGridSwitch
          data={orderLines}
          onChangeMode={(v) => setGridMode({ activated: v })}
          gridMode={gridMode.activated}
          tableColumns={orderColumns}
          onRequestData={async () => {
            const res = await getOrderPreparationLines(
              "LIVR",
              selectedPrepHeader.codeFourn,
              selectedPrepHeader.codeLieuLivr,
              selectedPrepHeader.panie,
              selectedPrepHeader.codeModeExpe
            );
            if (res) setOrderLines(res);
            else setOrderLines([]);
          }}
          renderGrid={() => <> </>}
        />
      </>
    </Page>
  );
};
