import { useRecoilState, useSetRecoilState } from "recoil";
import { ReceptionApiClient } from "../api-client/api-client";
import {
  MissingArticle,
  ReceptionLine,
  ReceptionUnit,
  RelatedOrder,
  SearchReceptionRequestType,
} from "../types";
import {
  AllOngoingReceptions,
  ReceiptLines,
  SelectedItemReception,
  SelectedReception,
  TabKey,
} from "./store";
import toast from "react-hot-toast";
import { LoadingState } from "@features/utils/store/loading-state-atom";
import { CommonPagination, CommonPaginationRequest } from "@features/types";
import { useProducts } from "@features/products/state/use-products";
import { toDateISO } from "@features/utils/format/dates";
import { AffectationCmdClientModalAtom } from "@views/client/reception/receptions-modals/affection-commande-client-modal";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";

export const useReception = () => {
  const [selectedReception, setSelectedReception] =
    useRecoilState(SelectedReception);

  const [selectedItem, setSelectedItem] = useRecoilState(SelectedItemReception);

  const [allOngoingReceipt, setAllOngoingReceipt] =
    useRecoilState(AllOngoingReceptions);

  const [receiptLines, setReceiptLines] = useRecoilState(ReceiptLines);
  const { getProductScan } = useProducts();
  const navigate = useNavigate();

  const [loading, setLoading] = useRecoilState(
    LoadingState(["useReception", false])
  );

  const setTabKey = useSetRecoilState(TabKey);

  const setAffectationModal = useSetRecoilState(AffectationCmdClientModalAtom);

  const getReceipt = async (receipt: ReceptionUnit) => {
    const result = await ReceptionApiClient.getReceipt(receipt);
    return { ...result };
  };

  const deleteReceipt = async (receipt: ReceptionUnit) => {
    try {
      const result = await ReceptionApiClient.deleteReceipt(receipt);
      if (result) {
        toast.success("Réception supprimée avec succès");
        navigate(ROUTES.Receptions);
        setLoading(false);
        return result;
      }
    } catch {
      setLoading(false);
      return false;
    }
  };

  const getLines = async (
    receipt: ReceptionUnit,
    pagination?: CommonPaginationRequest,
    loaderOn?: boolean,
    ean13?: string
  ) => {
    if (loaderOn) setLoading(true);
    const result = await ReceptionApiClient.getLines(
      receipt,
      pagination,
      ean13
    );
    setLoading(false);
    return result;
  };

  const refresh = async (pagination?: {
    orderBy?: string;
    orderDir: "ASC" | "DESC" | undefined;
    pageNumber: number;
    pageSize: 15;
  }) => {
    setLoading(true);
    try {
      const result = await getLines(selectedReception, pagination);
      setReceiptLines({
        pagination: {
          pageNumber: result.pageNumber,
          pageSize: result.pageSize,
          pageCount: result.pageCount,
          totalRows: result.totalRows,
          rowsReturned: result.rowsReturned,
        },
        items: result.items,
      });
      setLoading(false);
    } catch (err) {
      toast.error("Réception non trouvée");
      setLoading(false);
    }
    setLoading(false);
  };

  const mainSearch = async (search: SearchReceptionRequestType) => {
    if (search.query.trim()) {
      const result = await ReceptionApiClient.mainSearch(search);
      return result;
    }
    return [];
  };

  const createHeader = async (recept: ReceptionUnit) => {
    setLoading(true);
    try {
      const result = await ReceptionApiClient.createHeader(recept);
      setLoading(false);
      return result;
    } catch {
      setLoading(false);
      return false;
    }
  };

  const refreshAllOngoingReceipt = async (
    pagination?: CommonPaginationRequest,
    filters?: { utilCreat?: string; codeFourn?: string; trait?: boolean }
  ) => {
    setLoading(true);
    try {
      setAllOngoingReceipt(
        await ReceptionApiClient.allReceipt(pagination, filters)
      );
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const changeSelectedReception = (reception: ReceptionUnit) => {
    setSelectedReception(reception);
  };

  const printLabels = async (
    reception?: ReceptionUnit,
    receptionLine?: ReceptionLine,
    nbrEtiq?: number,
    linePk?: string,
    onlyCli?: boolean
  ) => {
    setLoading(true);

    try {
      await ReceptionApiClient.printLabels(
        reception ? reception : selectedReception,
        receptionLine,
        nbrEtiq,
        linePk,
        onlyCli
      );
    } catch {
      setLoading(false);
      toast.error("Impression des étiquettes impossible");
    }
    setLoading(false);
  };

  const changeReceiptLines = (lines: {
    pagination: CommonPagination;
    items: ReceptionLine[];
  }) => {
    setReceiptLines(lines);
  };

  const getHeaderReceipt = async (infos: {
    codeLieu: string;
    orig: "AE" | "COLIS" | "CDE" | "HCDE";
    codeFourn: string;
    numBL: string;
    numColis: string;
    numCde: string;
  }) => {
    setLoading(true);
    const result = await ReceptionApiClient.getHeaderReceipt(infos);
    setSelectedReception(result);
    setLoading(false);
    return { ...result, modeAffecCdeCli: "0", recepAffecCdeCli: "1" };
  };

  const receiptScannedProduct = async (ean: string, createRecep?: boolean) => {
    setLoading(true);
    const objectExist = await getProductScan(ean, true);
    if (objectExist) {
      if (createRecep) {
        const resCreat = await createHeader({
          ...selectedReception,
          modeGest:
            selectedReception.orig === "HCDE"
              ? objectExist.modeGest
              : selectedReception.modeGest,
        });
        if (!resCreat) {
          setLoading(false);
          return null;
        }
      }
      const result = await ReceptionApiClient.getScannedProduct({
        receipt: {
          codeFourn: selectedReception.codeFourn,
          numBL: selectedReception.numBL,
          seq: selectedReception.seq,
          codeLieu: selectedReception.codeLieu,
          dateRecep: toDateISO(selectedReception.dateRecep),
        },
        productId: ean,
      });
      setLoading(false);
      return result;
    } else {
      setLoading(false);
    }
  };

  const updateLine = async (
    product: ReceptionLine,
    orders?: RelatedOrder[],
    loaderOn?: boolean
  ) => {
    //if (loaderOn) setLoading(true);
    try {
      const result = await ReceptionApiClient.updateLine(
        selectedReception,
        product,
        orders
      );
      //setLoading(false);
      return result;
    } catch {
      //setLoading(false);
    }
  };

  const rappatrierLines = async (recept: ReceptionUnit) => {
    setLoading(true);
    try {
      const result = await ReceptionApiClient.rappatrierLines(recept);
      setLoading(false);
      return result;
    } catch {
      setLoading(false);
      return null;
    }
  };

  const closeReceipt = async () => {
    setLoading(true);
    try {
      const result = await ReceptionApiClient.closeReceipt(selectedReception);
      setLoading(false);
      return result;
    } catch {
      setLoading(false);
      return false;
    }
  };

  const getReceiptDocument = async (documentType: "ecart" | "recep") => {
    setLoading(true);
    try {
      const result = await ReceptionApiClient.getReceiptDocument(
        selectedReception,
        documentType
      );
      setLoading(false);
      return result;
    } catch {
      setLoading(false);
    }
  };

  const getRelatedOrders = async (
    receipt: ReceptionUnit,
    productId: string,
    linePk: string,
    qteRecu: number,
    loaderOn?: boolean
  ) => {
    loaderOn && setLoading(true);
    const result = await ReceptionApiClient.getRelatedOrders(
      receipt,
      productId,
      linePk,
      qteRecu
    );
    loaderOn && setLoading(false);
    return result;
  };

  const handleCommit = async (
    selectedItem: ReceptionLine,
    refresh?: boolean
  ) => {
    if (selectedItem)
      if (selectedItem.qteCdeCli > 0 && selectedItem.qteRecu > 0) {
        const orders = await getRelatedOrders(
          selectedReception,
          selectedItem.linePk,
          selectedItem.eaN13,
          selectedItem.qteRecu
        );

        setAffectationModal({
          open: true,
          receipt: selectedReception,
          item: selectedItem,
          orders: orders,
        });
      } else {
        await updateLine(
          selectedItem,
          [
            {
              codeCanalVente: "",
              numCdeCli: "",
              dateCde: null,
              nomClient: "",
              telCli: "",
              mailCli: "",
              qteCde: 0,
              qteRest: 0,
              qteProposee: selectedItem.qteRecu,
              nbrEtiq: 0,
              linePk: "",
            },
          ],
          refresh
        );
        if (refresh)
          setTabKey((previous) => {
            return { count: previous.count + 1, refresh: true };
          });
      }
  };

  const updateAllPrices = async () => {
    const res = await ReceptionApiClient.updateAllPrices(selectedReception);
    if (res) toast.success("Prix mis à jour avec succès");
  };

  const getMissingArticles = async () => {
    const articles = await ReceptionApiClient.getMissingArticles(
      selectedReception
    );
    return articles;
  };

  const getMissingCliOrders = async (
    ean13: string,
    codeLieu: string,
    numCde: string
  ) => {
    const articles = await ReceptionApiClient.getMissingCliOrders(
      ean13,
      codeLieu,
      numCde
    );
    return articles;
  };

  const updateMissingArticles = async (missingArticles: MissingArticle[]) => {
    return await ReceptionApiClient.updateMissingArticles(
      selectedReception,
      missingArticles
    );
  };

  const debouncedFetchValoLine = debounce(
    async (product: ReceptionLine, key: string, value: string) => {
      const res =
        (key === "prixBase" || "prixNetHt") && value === ""
          ? { tauxNet: product.tauxNet, netHT: product.prixNetHt }
          : await ReceptionApiClient.valoLine(
              selectedReception,
              {
                ...product,
                [key]: value,
              },
              key
            );
      if (res) {
        if (selectedItem) {
          setSelectedItem({
            ...selectedItem,
            [key]: value === "" && key === "prixBase" ? "" : +value,
            tauxNet: res.tauxNet,
            prixNetHt: value === "" && key === "prixNetHt" ? "" : res.netHT,
          } as ReceptionLine);
        } else {
          //Temp fix
          let items = [...receiptLines.items];
          if (items.length === 0) {
            const lineRes = await getLines(selectedReception);
            items = [...lineRes.items];
          }
          //

          const indexP = items.findIndex((p) => p.linePk === product.linePk);
          const tempTab = [...items];
          tempTab[indexP] = {
            ...product,
            [key]: value === "" ? "" : +value,
            tauxNet: res.tauxNet,
            prixNetHt: value === "" && key === "prixNetHt" ? +"" : res.netHT,
          };
          setReceiptLines({ ...receiptLines, items: tempTab });
        }
      }
    },
    200,
    { leading: true }
  );

  const updateFdp = async (receipt: ReceptionUnit) => {
    setLoading(true);
    try {
      const res = await ReceptionApiClient.updateFdp(receipt);
      setLoading(false);
      return res;
    } catch {
      setLoading(false);
      return false;
    }
  };

  const valoLine = (product: ReceptionLine, key: string, value: string) => {
    debouncedFetchValoLine(product, key, value);
  };

  return {
    getReceipt,
    deleteReceipt,
    mainSearch,
    allOngoingReceipt,
    refreshAllOngoingReceipt,
    selectedReception,
    changeSelectedReception,
    changeReceiptLines,
    setReceiptLines,
    getLines,
    loading,
    refresh,
    receiptLines,
    getHeaderReceipt,
    receiptScannedProduct,
    getRelatedOrders,
    createHeader,
    updateLine,
    rappatrierLines,
    closeReceipt,
    printLabels,
    getReceiptDocument,
    handleCommit,
    getMissingArticles,
    getMissingCliOrders,
    updateAllPrices,
    updateMissingArticles,
    updateFdp,
    valoLine,
  };
};
