import { OptionsCommonModal } from "@molecules/options-modal";
import { AccountModal } from "./client/account/modal";
import { AccountPasswordModal } from "./client/account/password-change-modal";
import { HistoryCommandModal } from "@molecules/history-command-modal";
import { ReceptionInfoModal } from "@views/client/reception/receptions-modals/reception-info-modal";
import { ReceptionProductListModal } from "@views/client/reception/receptions-modals/reception-product-list-modal";
import { AffectationCmdClientModal } from "./client/reception/receptions-modals/affection-commande-client-modal";
import { HtmlModal } from "@molecules/form/htmlFormModal";
import { ConfirmCommonModal } from "@molecules/confirm-modal";
import { InactivModal } from "./client/account/inactiv-modal";
import { FinalizedOrderModal } from "@components/order/finalize-order-modal";
import { CommandFournModal } from "./client/reception/receptions-modals/commande-fournisseur-modal";
import { PrintLabelsReceptionModal } from "./client/reception/receptions-modals/print-labels-modal";
import { PdfViewerModal } from "@molecules/pdf-viewer/pdf-viewer-modal";
import { UnknownArticlesModal } from "./client/reception/receptions-modals/unknown-articles-modal";
import { MissingArticlesModal } from "./client/reception/receptions-modals/missing-articles";
import { LieuStockModal } from "./client/products/product/lieu-stock-modal";
import { ImageModal } from "@molecules/image-modal";
import { CommandClientModal } from "./client/reception/receptions-modals/commande-client-modal";
import { SupplierProductsModal } from "./client/reception/receptions-modals/list-supplier-products-modal";
import { ProductGraphModal } from "./client/fournisseur/graph-modal";
import { TableConfigModal } from "@molecules/table/table-config-modal";
import { SendSupplierOrderModal } from "./client/commandes-fournisseur/send-order-modal";
import { SearchProductModal } from "./client/commandes-fournisseur/search-product-modal";

export const Modals = () => {
  return (
    <>
      <OptionsCommonModal />
      <ConfirmCommonModal />
      <AccountModal />
      <InactivModal />
      <AccountPasswordModal />
      <HtmlModal />
      <ImageModal />
      <HistoryCommandModal />
      <LieuStockModal />
      <SupplierProductsModal />
      <ReceptionInfoModal />
      <PrintLabelsReceptionModal />
      <ReceptionProductListModal />
      <MissingArticlesModal />
      <UnknownArticlesModal />
      <CommandClientModal />
      <CommandFournModal />
      <AffectationCmdClientModal />
      <FinalizedOrderModal />
      <PdfViewerModal />
      <ProductGraphModal />
      <TableConfigModal />
      <SendSupplierOrderModal />
      <SearchProductModal />
    </>
  );
};
