import { useScan } from "@atoms/input-scan/use-scan";
import { ScanTriggerIconDomId } from "./_layout/scan";
import { QrcodeIcon, XIcon } from "@heroicons/react/outline";
import { createPortal } from "react-dom";
import { useState } from "react";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";

export const ScanIconTriggerWidget = () => {
  const { scanOn, cameraAvailable, openScan, closeScan } = useScan();
  const [pageWithScan, setPageWithScan] = useState(false);
  const el = document.getElementById(ScanTriggerIconDomId);

  useControlledEffect(() => {
    setTimeout(() => {
      const scanEl = document.querySelector(".to-focus");
      if (scanEl) setPageWithScan(true);
      else setPageWithScan(false);
    }, 200);
  }, [document.URL]);

  return (
    <>
      {el &&
        cameraAvailable &&
        pageWithScan &&
        createPortal(
          <div
            style={{ zIndex: 1000000 }}
            data-tooltip={scanOn ? "" : "Open barcode scanner"}
            className="fixed right-4 bottom-4 p-2 rounded-full bg-white shadow-lg cursor-pointer text-blue-600 hover:text-blue-800 hover:bg-blue-100 print:hidden"
            onClick={() => {
              if (!scanOn) {
                openScan();
              } else {
                closeScan();
              }
            }}
          >
            {!scanOn && <QrcodeIcon className="h-8 w-8" />}
            {!!scanOn && <XIcon className="h-8 w-8" />}
          </div>,
          el
        )}
    </>
  );
};
