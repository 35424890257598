import { Page } from "@atoms/layout/page";
import { Section, Title } from "@atoms/text";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { Table } from "@molecules/table";

export const SupplierPrepOrderPage = () => {
  const { loading, supplierOrderList, getOrderPreparationHeaders } =
    useSuppliers();

  return (
    <Page loading={loading}>
      <div className="w-full flex justify-between">
        <Title>Commandes fournisseurs en préparation</Title>
      </div>
      <div className="grow overflow-hidden">
        <Section>Liste des commandes fournisseurs en préparation</Section>
        <Table
          data={supplierOrderList.items}
          codePres="supplier"
          showPagination={"full"}
          cellClassName={() => "rounded-lg"}
          initialPagination={{
            perPage: 15,
            page: 1,
            order: "DESC",
            orderBy: 0,
          }}
          onClick={async () => {}}
          exportDefaultHeader={[]}
          columns={[]}
          total={supplierOrderList.pagination.totalRows}
          onRequestData={async ({ page, perPage, order, orderBy }) => {
            await getOrderPreparationHeaders("LIVR", "MAG");
          }}
          gridClassName={
            "grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
          }
        />
      </div>
    </Page>
  );
};
